import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup, Container, ModalBody, Modal, Col, Row } from "reactstrap";
import { postProjectRequest } from '../../../reducers/Requests/actions'
import { validateEmail, validatePhone, nameRegex, phoneRegex, locationRegex } from '../../../utils/util'
import CustomizedCheckbox from '../../Checkbox/Checkbox';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import "./styles.scss";

const ProjectModal = ({ projectId, isOpen, onClick, title, postProjectRequest }) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [description, setDescription] = useState('')
  const [gdpr, setGDPR] = useState(false)
  const [terms, setTerms] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [validate, setValidate] = useState(false)

  useEffect(() => {
    setGDPR(false)
    setTerms(false)
    setMarketing(false)
    setValidate(false)
  }, [])

  const validateFields = () => {
    if (name.length === 0 || !nameRegex.test(name) || !phoneRegex.test(phone) || phone.length === 0 ||
      !validateEmail(email) || email.length === 0 || location.length === 0 || location.length > 35 ||
      description.length === 0 || !gdpr || !terms || !marketing) {
      return true
    } else return false
  }

  const validateInputs = (name, phone, email, location) => {
    if (!nameRegex.test(name) || name.length > 35 || name.length === 0) {
      return 'Невалидно име! Допустими символи: латиница и кирилица.'
    }
    if (!phoneRegex.test(phone) || phone.length > 25 || phone.length === 0) {
      return 'Невалиден телефонен номер! Допустими символи: само цифри.'
    }
    if (!validateEmail(email) || email.length > 254 || email.length === 0) {
      return 'Невалиден имейл адрес! Допустими символи: латиница, цифри, и специални символи.'
    }
    if (location.length > 35 || location.length === 0) {
      return 'Невалидна локация! Допустими символи до 35'
    }
    if (description.length === 0) {
      return 'Въведете съобщение!'
    }
    if (!gdpr) {
      return 'Липсва съгласие за обработка на лични данни.'
    }
    if (!terms) {
      return 'Липсва съгласие за правилата за ползване.'
    }
    if (!marketing) {
      return 'Липсва съгласие за правилата за маркетинг.'
    }
    return ''
  }

  return (
    <div className="modal-wrapper">
      <Modal size="lg project-modal" isOpen={isOpen}>
        <div className="close-btn d-flex justify-content-end">
          <i
            role="none"
            className="close-btn"
            onClick={() => {
              setName('')
              setPhone('')
              setEmail('')
              setLocation('')
              setDescription('')
              setTerms(false)
              setGDPR(false)
              setMarketing(false)
              setValidate(false)
              onClick()
            }}>
            <CloseIcon />
          </i>
        </div>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <h4 className="font-weight-bold no-decoration text-center mb-4">
                {title}
              </h4>
            </Col>
          </Row>

          <Row className='my-3 mx-auto'>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  maxLength={35}
                  placeholder="Име и фамилия"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setName(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  maxLength={25}
                  type='number'
                  placeholder="Телефон"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setPhone(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  type='email'
                  maxLength={254}
                  placeholder="Имейл"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setEmail(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  type='text'
                  maxLength={25}
                  placeholder="Локация"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setLocation(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12}>
              <div>
                <Input
                  className="project-input-textarea py-3 border-radius box-shadow"
                  type="textarea"
                  placeholder="Съобщение"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <div className='my-3'>
            <Row className="privacy-checkbox-row w-100">
              <div className='py-2 d-flex align-items-center'>
                <CustomizedCheckbox
                  checked={gdpr}
                  onChange={() => setGDPR(!gdpr)}
                />
                <label className="checkbox-label" htmlFor="privacy">
                  <p className='my-0'>
                    Съгласен съм личните ми данни да бъдат обработвани от "КСЕЛА България" ЕООД в съответствие с <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/GDPR`, "_blank")}>Политиката за защита на личните данни на КСЕЛА България </a>.
                  </p>
                </label>
              </div>
              <div className='py-2 d-flex align-items-center'>
                <CustomizedCheckbox
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                />
                <label className="checkbox-label" htmlFor="privacy">
                  <p className='my-0'>Съгласен съм и приемам <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/terms-and-conditions?terms-and-conditions=0`, "_blank")}>правилата за ползване на уебсайта</a>.</p>
                </label>
              </div>
              <div className='py-2 d-flex align-items-center'>
                <CustomizedCheckbox
                  checked={marketing}
                  onChange={() => setMarketing(!marketing)}
                />
                <label className="checkbox-label" htmlFor="privacy">
                  <p className='my-0'>
                    Съгласен съм личните ми данни да бъдат използвани за маркетингови цели.
                  </p>
                </label>
              </div>

            </Row>
          </div>
          <hr />
          <div>
            {
              validateFields() && validate &&
              <div className='data-alert mt-3 mx-auto px-2'>
                {validateInputs(name, phone, email, location)}
              </div>
            }
          </div>
          <div className="no-decoration text-center text-black-50">
            <Container className="mt-3 btn-container d-flex justify-content-center">
              <Button
                className="box-shadow custom-btn w-50 py-3 text-uppercase mb-3"
                onClick={() => {
                  if (validateFields()) {
                    setValidate(true)
                    return
                  }
                  postProjectRequest({
                    project_id: Number(projectId),
                    full_name: name,
                    phone_number: phone,
                    email,
                    location,
                    description,
                    gdpr_consent: gdpr,
                    terms_consent: terms,
                    marketing_consent: marketing
                  })
                  setName('')
                  setPhone('')
                  setEmail('')
                  setLocation('')
                  setDescription('')
                  setTerms(false)
                  setGDPR(false)
                  setMarketing(false)
                  setValidate(false)
                  onClick()
                }}
              >
                Изпрати запитване
              </Button>
            </Container>
          </div>
        </ModalBody>
      </Modal>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    serviceType: state.home.serviceType
  };
};

const mapDispatchToProps = {
  postProjectRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectModal);
