import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../components/Layout";
import ProjectModal from "../../components/Modals/ProjectModal/ProjectModal";
import Gallery from "../../components/CarouselGallery/CarouselGallery";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal'
import { getProjectDetails } from "../../reducers/Projects/actions";
import { setModal } from '../../reducers/Home/actions'
// import defaultImage from '../../assets/images/xella-default-small.png';
import { returnArchitectName } from '../../utils/helpers'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import "./styles.scss";


const SingleProjectPage = ({
  details,
  successModal,
  errorModal,
  setModal,
  pageContext,
  getProjectDetails
}) => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImage] = useState(0);

  let serviceId = pageContext && pageContext.item && pageContext.item.id;
  const projectData = pageContext.item;

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }


  useEffect(() => {
    if (typeof window !== "undefined") {
      url = new URL(window.location.href);
      serviceId = url.pathname.substring(17, serviceId.length);
      getProjectDetails({ id: serviceId })
    }
  }, [])

  // const fillWithDefaultImage = () => {
  //   if (projectData && projectData.other_images.length < 8) {
  //     let imagesNeeded = 8 - projectData.other_images.length;

  //     return projectData.other_images.map((photo, i) => {
  //       while (i < imagesNeeded) {
  //         i += 1;
  //         return (
  //           <div
  //             key={i}
  //             style={{
  //               backgroundPosition: 'center center',
  //               backgroundSize: 'cover',
  //               backgroundImage: `url(${defaultImage})`
  //             }}
  //             className={`project_gallery__item--6`}>
  //           </div>
  //         )
  //       }
  //     })
  //   }
  //   return null;
  // }

  let imageResponse = getImage(pageContext.item?.other_images[0].localFile.childImageSharp)

  return (
    <Layout
      url={url}
      title={pageContext && pageContext.item?.title}
      image={pageContext && pageContext.item?.other_images && imageResponse} 
      description={pageContext && pageContext.item?.description}
      >
      <div className="single-project-wrapper">
        <div className="single-project-container">
          <Row className="single-project-row">
            <Col className='order-2 order-lg-1 mb-3' sm={12} lg={4} >
              <div className="mb-2 card-description">
                {pageContext && pageContext.item?.creation_date}
              </div>
              <h1 className=" font-weight-bold">{pageContext && pageContext.item?.title}</h1>
              <div className="counter-container">
                <div className="card-description">
                  <span className="color-black">на </span>
                  {pageContext && returnArchitectName(pageContext.item?.author_name)}
                </div>
                <div className="mt-4 counter">
                  <Row className="counter-row">
                    <Col className="py-1 counter-col-1">
                      <div>видяно</div>
                    </Col>
                    <Col className="py-1 counter-col-2">
                      <div>{details && details.views_count}</div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div>{pageContext && pageContext.item?.description}</div>
              <Button
                className="px-5 py-3 my-3 justify-content-start service-details-btn text-uppercase "
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Изпрати запитване
              </Button>
              <div>
                <FacebookShareButton
                imageURL={details && details.image && details.image.url && `https://building-companion.bg/${details.image.url}`}
                  url={serviceId && `https://building-companion.bg/project-details/${serviceId}`}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                  <LinkedinShareButton
                    className="margin-left5"
                    url={serviceId && `https://building-companion.bg/project-details/${serviceId}`}               
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
              </div>
              <div className="all-projects-link-container d-xs-none d-lg-flex mt-5">
                <Link to={"/projects"} className="blue-link">
                  Всички проекти
                </Link>
              </div>
            </Col>
            <Col className='order-1 order-lg-2 mb-3' sm={12} lg={8}>
              <div>
                <div className='photo-gallery-wrapper'>
                  <section
                    role="button"
                    tabIndex={0}
                    className="project_gallery"
                  >
                    {pageContext &&
                      pageContext.item?.other_images.map((photo, i) => {
                        const gatsbyImage = photo.localFile.childImageSharp
                        if (i < 7) {
                          return (
                            <div role='none'
                              onKeyDown={() => setGalleryOpen(!isGalleryOpen)}
                              onClick={() => {
                                setGalleryOpen(!isGalleryOpen);
                                setCurrentImage(i)
                              }}
                              key={i}
                              className={`d-flex project_gallery__item--${i + 1}`}>
                              <GatsbyImage
                                image={getImage(gatsbyImage)}
                                alt=""
                                objectFit="cover"
                                layout="fixed"
                              />
                            </div>
                          );
                        }

                      })}
                    {/* {fillWithDefaultImage()} */}
                  </section>
                </div>
              </div>
            </Col>
          </Row>
          {
            details &&
            details.sections.map((s, i) => {
              return (
                <Row
                  key={i}
                  className="py-5 single-project-row">
                  <Col>
                    <div className="single-project-description-container">
                      <h4 className='description-title'>{s.title}</h4>
                      <div
                        className="pb-4"
                        dangerouslySetInnerHTML={{ __html: s.description && s.description }} />
                    </div>
                  </Col>
                </Row>
              );
            })
          }
        </div >
      </div >
      <Gallery
        onClick={() => setGalleryOpen(!isGalleryOpen)}
        isOpen={isGalleryOpen}
        projectData={projectData}
        otherImages={projectData && projectData?.other_images}
        imageIndex={currentImageIndex}
      />
      <ProjectModal
        title="Изпрати запитване"
        isOpen={isModalOpen}
        onClick={() => setModalOpen(!isModalOpen)}
        projectId={serviceId}
      />
      <ConfirmationModal
        title={errorModal ? `Нещо се обърка, моля опитайте отново по-късно.` : `Изпратихте успешно запитване за проект.`}
        isOpen={successModal}
        onClick={() => setModal()}
      />
    </Layout >
  );
};

const mapStateToProps = (state) => {
  return {
    details: state.projects.details,
    successModal: state.home.successModal,
    errorModal: state.home.errorModal
  };
};

const mapDispatchToProps = {
  setModal,
  getProjectDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleProjectPage);
